import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

import List from '@material-ui/core/List'
import Icon from '@material-ui/core/Icon'

import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import IconExpandMore from "@material-ui/icons/ExpandMore"
import IconExpandLess from "@material-ui/icons/ExpandLess"


const appMenuItems = [
    {
        name: 'Dashboard',
        link: '/',
        Icon: IconDashboard,
    },
    {
        name: 'Item 1',
        link: '/item1',
        Icon: IconShoppingCart,
    },
    {
        name: 'Item 2',
        link: '/item2',
        Icon: IconPeople,
    },
    {
        name: 'Items',
        link: '/items',
        Icon: IconBarChart,
    }
]

const handleClick = (a, b, c) => {
    console.log(a, b, c)
    return () => {
        // const [open, setOpen] = React.useState(false)
        // setOpen(!open)
        // React.navigateTo(a.link)
        console.log(this, a, b, c)
    }
}

const AppMenu: React.FC = () => {
    const classes = useStyles()

    return (
        <List component="nav" className="appMenu" disablePadding>
            {/* <AppMenuItem {...appMenuItems[0]} /> */}
            {appMenuItems.map((item, index) => (
                <ListItem button component="a" key={index} href={item.link}>
            {/* Display an icon if any */}
            {!!item.Icon && (
                <ListItemIcon>
                    <Icon component={item.Icon}/>
                </ListItemIcon>
            )}
                <ListItemText primary={item.name} inset={!item.Icon} />
            {/* Display the expand menu if the item has children */}
            {item.isExpandable && !item.open && <IconExpandMore />}
            {item.isExpandable && item.open && <IconExpandLess />}
            </ListItem>
            ))}
        </List>
    )
}

const drawerWidth = 240

const useStyles = makeStyles(theme =>
    createStyles({
        appMenu: {
            width: '100%',
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth,
        },
        menuItemIcon: {
            color: '#97c05c',
        },
    }),
)

export default AppMenu
